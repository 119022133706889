import revive_payload_client_puNnnNdpcH from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.20.0_jiti@2.4._739a8158f1bcaeedc88dc08a2f1da994/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_xBlfjL6DCs from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.20.0_jiti@2.4._739a8158f1bcaeedc88dc08a2f1da994/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_SfFAwIyK67 from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.20.0_jiti@2.4._739a8158f1bcaeedc88dc08a2f1da994/node_modules/nuxt/dist/app/plugins/router.js";
import payload_client_OUm8DB0NkO from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.20.0_jiti@2.4._739a8158f1bcaeedc88dc08a2f1da994/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_SJcK45oypW from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.20.0_jiti@2.4._739a8158f1bcaeedc88dc08a2f1da994/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client_pUXTDR2CbR from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.20.0_jiti@2.4._739a8158f1bcaeedc88dc08a2f1da994/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_zbacBAXhj0 from "/app/.nuxt/modules/@nuxt-scripts/plugin.mjs";
import plugin_vue3_KfbI4lR8CT from "/app/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_typescript@5.7.3_vue@3.5.13_typescript@5.7.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import plugin_d4yY4Rgevc from "/app/node_modules/.pnpm/@nuxtjs+device@3.1.1_magicast@0.3.5/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import plugin_s43eAa0KTE from "/app/node_modules/.pnpm/@petlove-npm-gcp-registry+pikachu@2.1.2_@nuxt+devtools@1.7.0_rollup@4.34.6_vite@6.1.0_@_0dfaa011c54fb858e432b5ce3f362902/node_modules/@petlove-npm-gcp-registry/pikachu/dist/runtime/plugins/honeybadger/plugin.js";
import fingerprint_client_MRVhBzQ8sp from "/app/node_modules/@petlove/lion/plugins/fingerprint.client.js";
import googleLogin_client_etu5Zz6iA1 from "/app/node_modules/@petlove/lion/plugins/googleLogin.client.js";
import gtm_faF6itaviB from "/app/node_modules/@petlove/lion/plugins/gtm.js";
import setZipcode_client_wGagZYc09E from "/app/node_modules/@petlove/lion/plugins/setZipcode.client.js";
import sso_client_dDsvoRZwZH from "/app/node_modules/@petlove/lion/plugins/sso.client.js";
import userPublic_client_A5JbdSOqye from "/app/node_modules/@petlove/lion/plugins/userPublic.client.js";
import userSession_client_tyKijbagHL from "/app/node_modules/@petlove/lion/plugins/userSession.client.js";
import error_handler_VFH3VvK7yG from "/app/plugins/error-handler.js";
import setUtms_client_JfUnysW9Os from "/app/plugins/setUtms.client.js";
export default [
  revive_payload_client_puNnnNdpcH,
  unhead_xBlfjL6DCs,
  router_SfFAwIyK67,
  payload_client_OUm8DB0NkO,
  navigation_repaint_client_SJcK45oypW,
  chunk_reload_client_pUXTDR2CbR,
  plugin_zbacBAXhj0,
  plugin_vue3_KfbI4lR8CT,
  components_plugin_KR1HBZs4kY,
  plugin_d4yY4Rgevc,
  plugin_s43eAa0KTE,
  fingerprint_client_MRVhBzQ8sp,
  googleLogin_client_etu5Zz6iA1,
  gtm_faF6itaviB,
  setZipcode_client_wGagZYc09E,
  sso_client_dDsvoRZwZH,
  userPublic_client_A5JbdSOqye,
  userSession_client_tyKijbagHL,
  error_handler_VFH3VvK7yG,
  setUtms_client_JfUnysW9Os
]